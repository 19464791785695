body {
	background-image: url(../img/bg.png);
	background-repeat: repeat;
	background-position: left top;
}

//
// Layouts
//

// Navbar

.navbar {
	.navbar-brand {
		margin-left: auto;
		margin-right: auto;
		padding-left: $navbar-toggler-width;
	}
}

.offcanvas {
	.nav {
		flex-direction: column;
	}
	
	.nav-item {
		border-bottom: solid $border-width $gray-200;
		
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.nav-link {
		padding-left: 0;
		padding-right: 0;
		
		&:not(:hover),
		&:not(:focus) {
			color: $primary;
		}
	}
}

@include media-breakpoint-up(md) {
	.navbar-brand {
		padding-left: 0;
	}
	
	.navbar-toggler {
		display: none;
	}
	
	.offcanvas {
		&,
		&[style] {
			visibility: visible !important;
		}
		
		position: static;
		transform: none;
		width: auto;
		border-right: 0;
		
		&-header {
			display: none;
		}
		
		&-body {
			padding-top: 0;
			padding-bottom: 0;
		}
		
		.nav {
			flex-direction: row;
			justify-content: center;
		}
		
		.nav-item {
			border-bottom: 0;
		}
		
		.nav-link {
			position: relative;
			padding-left: $nav-link-padding-x * 2;
			padding-right: $nav-link-padding-x * 2;
			padding-bottom: $nav-link-padding-y * 2;
			@include transition($transition-base);
			
			&:hover,
			&:focus {
				background-color: $gray-100;
			}
			
			&::before {
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -($font-size-base * .5);
				margin-bottom: $nav-link-padding-y * .5;
				color: $gray-500 !important;
				@include font-size($font-size-sm);
			}
		}
	}
}

// Footer

.footer {
	@include font-size($font-size-sm);
}

.footer {
	padding-top: $navbar-padding-y;
	padding-bottom: $navbar-padding-y;
	
	.navbar-brand {
		display: block;
		margin-bottom: $paragraph-margin-bottom;
		text-align: center;
	}
	
	.nav {
		margin-bottom: $paragraph-margin-bottom;
		flex-direction: column;
	}
	
	.nav-item {
		border-bottom: solid $border-width $gray-300;
		
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.nav-link {
		padding-left: 0;
		padding-right: 0;
	}
	
	@include media-breakpoint-up(md) {
		.nav {
			flex-direction: row;
			justify-content: center;
		}
		
		.nav-item {
			border-bottom: 0;
			border-right: solid $border-width $gray-300;
			
			&:last-child {
				border-right: 0;
			}
		}
		
		.nav-link {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: $nav-link-padding-x;
			padding-right: $nav-link-padding-x;
		}
	}
}

.credit {
	text-align: center;
}

.pagetop {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: $grid-gutter-width * .5;
	z-index: $zindex-fixed;
	
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: $white;
		background-color: $primary;
		@include border-radius($border-radius-pill);
	}
}

// Content

.content {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
	background-color: $white;
}

.main,
.side {
	@include make-col-ready();
}

.main {
	margin-bottom: $grid-gutter-width;
}

@include media-breakpoint-up(md) {
	.content {
		padding-top: $grid-gutter-width * 2;
		padding-bottom: $grid-gutter-width * 2;
	}
	
	.main {
		margin-bottom: 0;
		@include make-col(8, $grid-columns);
	}
	
	.side {
		@include make-col(4, $grid-columns);
	}
}

@include media-breakpoint-up(xl) {
	.main {
		@include make-col(9, $grid-columns);
	}
	
	.side {
		@include make-col(3, $grid-columns);
	}
}
