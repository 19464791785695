//
// Icons
//

@import "~bootstrap-icons/font/bootstrap-icons";

.blockquote,
.breadcrumb-item:first-of-type a,
.offcanvas .nav-link {
	&::before {
		display: inline-block;
		margin-right: .25rem;
		font-family: $bootstrap-icons-font !important;
		font-style: normal;
		font-weight: normal !important;
		font-variant: normal;
		text-transform: none;
		color: currentColor;
		line-height: 1;
		vertical-align: -.125em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.blockquote:before {
	content: map-get($bootstrap-icons-map, "quote");
}

.breadcrumb-item:first-of-type a::before {
	content: map-get($bootstrap-icons-map, "house-door-fill");
}

.offcanvas .nav-link::before {
	content: map-get($bootstrap-icons-map, "arrow-right-square-fill");
	margin-right: .5rem;
	
	@include media-breakpoint-up(md) {
		content: map-get($bootstrap-icons-map, "chevron-down");
		margin-right: 0;
	}
}
