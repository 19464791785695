//
// Contents
//

.section {
	+ .section {
		margin-top: $grid-gutter-width !important;
	}
}

.section-title {
	padding: $headings-margin-bottom * .5;
	color: $white;
	background-color: $primary;
	box-shadow: 3px 3px 0 rgba($gray-600, .25);
	@include font-size($font-size-lg);
	
	.colophon & {
		margin-bottom: $paragraph-margin-bottom;
		padding: 0 $spacer * .5;
		color: inherit;
		background-color: transparent;
		border: 0;
		border-left: solid 3px $secondary;
		text-align: left;
		@include font-size($font-size-base);
	}
}

// Main contents

.main {
	.card {
		border: 0;
		background-color: transparent;
	}
	
	.card-header {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		
		.badge {
			margin-bottom: $paragraph-margin-bottom;
		}
	}
	
	.card-footer {
		padding-left: 0;
		padding-right: 0;
	}
	
	.card-body {
		padding-left: 0;
		padding-right: 0;
		@include font-size($font-size-lg);
	}
	
	.card-title {
		@include font-size($h1-font-size);
	}
	
	.h1 {
		padding: $spacer * .5 $spacer;
		color: $white;
		background-color: rgba($primary, .8);
		box-shadow: 3px 3px 0 rgba($gray-600, .25);
		@include font-size($h2-font-size);
	}
	
	.h2 {
		position: relative;
		padding: $spacer * .5 $spacer $spacer * .5 calc(#{$spacer} + 10px);
		border: 3px solid rgba($primary, .5);
		@include font-size($h3-font-size);
		
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 10px;
			height: 100%;
			background-image: repeating-linear-gradient(
				-45deg,
				rgba($primary, .5),
				rgba($primary, .5) 1px,
				transparent 2px,
				transparent 5px
			);
			background-size: 7px 7px;
			backface-visibility: hidden;
		}
	}
	
	.h3 {
		position: relative;
		padding-bottom: $headings-margin-bottom;
		@include font-size($h4-font-size);
		
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 10px;
			background-image: repeating-linear-gradient(
				-45deg,
				$primary,
				$primary 1px,
				transparent 2px,
				transparent 5px
			);
			background-size: 7px 7px;
			backface-visibility: hidden;
		}
	}
}

// Indexes

.indexes {
	margin: 0 auto $paragraph-margin-bottom;
	background-color: $gray-100;
	overflow: hidden;
	box-shadow: 3px 3px 0 rgba($gray-600, .25);
	@include font-size($font-size-base);
	
	a {
		display: block;
	}
	
	ol {
		margin: $spacer * .5 $spacer;
		list-style: none;
		
		li {
			&:not(:last-of-type) {
				border-bottom: dashed $border-width $gray-300;
			}
			
			a {
				padding-top: $spacer * .5;
				padding-bottom: $spacer * .5;
			}
			
			ol {
				margin: 0;
				border-top: dashed $border-width $gray-200;
				
				li {
					padding-left: $spacer * 1.5;
				}
			}
		}
	}
	
	.collapse-toggler {
		padding: $spacer * .5 0;
		text-decoration: none;
		text-align: center;
		color: $white;
		background-color: $secondary;
	}
	
	@include media-breakpoint-up(md) {
		width: 80%;
	}
}

// Profile

.profile {
	display: flex;
	align-items: flex-start;
	padding: $spacer * .5 $spacer;
	border: solid 5px $gray-200;
	
	&-avatar {
		width: 100px;
	}
	
	&-content {
		flex: 1;
		margin-left: $spacer;
		
		p {
			margin-bottom: $paragraph-margin-bottom * .5;
			
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// Breadcrumbs

.breadcrumb {
	padding-top: $grid-gutter-width * .5;
	padding-bottom: $grid-gutter-width * .5;
	
	a {
		color: $orange-600;
		text-decoration: none;
	}
}

//
// Commons
//

.anchor {
	padding-top: $spacer * 2;
}

.avatar {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $paragraph-margin-bottom;
	text-align: center;
	
	img {
		background-color: rgba($secondary, .5);
		@include border-radius($border-radius-pill);
	}
}

// Nav

.nav {
	&.flex-column {
		.nav-item {
			margin-bottom: $nav-link-padding-y;
			padding-bottom: $nav-link-padding-y;
			border-bottom: solid $border-width $border-color;
			
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0;
			}
		}
		
		.nav-link {
			padding: 0;
			color: $body-color;
			@include transition($transition-base);
			
			&:hover {
				transform: translateX(3px);
				color: $teal;
			}
		}
	}
}

// Share

.share {
	display:flex;
	align-items: center;
	justify-content: center;
	
	a {
		display: block;
		margin-left: $spacer * .25;
		margin-right: $spacer * .25;
		padding: $spacer * .5;
		text-align: center;
		line-height: 1;
	}
	
	.main & {
		margin-left: -$spacer * .25;
		margin-right: -$spacer * .25;
		margin-bottom: $paragraph-margin-bottom;
		
		a {
			flex: 1 1 auto;
		}
	}
	
	.side & {
		padding: $spacer;
		color: $white;
		background-color: $secondary;
		
		a {
			color: $white;
			background-color: rgba($white, .2);
		}
	}
}

.share-link {
	@include transition($transition-base);
	
	&-1 {
		color: $white;
		background-color: $cyan;
		border: solid 2px $cyan;
		
		&:hover {
			color: $cyan;
			background-color: $white;
		}
	}
	
	&-2 {
		color: $white;
		background-color: $indigo;
		border: solid 2px $indigo;
		
		&:hover {
			color: $indigo;
			background-color: $white;
		}
	}
	
	&-3 {
		color: $white;
		background-color: $blue-600;
		border: solid 2px $blue-600;
		
		&:hover {
			color: $blue-600;
			background-color: $white;
		}
	}
	
	&-4 {
		color: $white;
		background-color: $red;
		border: solid 2px $red;
		
		&:hover {
			color: $red;
			background-color: $white;
		}
	}
	
	&-5 {
		color: $white;
		background-color: $green;
		border: solid 2px $green;
		
		&:hover {
			color: $green;
			background-color: $white;
		}
	}
}
